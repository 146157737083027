import Loader from "../Loader";

const DateRangeLoader = () => {
  return (
    <div className="p-[16px] md:p-[32px] bg-[#2a323f] flex flex-col items-center justify-center">
      <p className="text-white text-center text-lg font-semibold mb-6">
        Loading Date Range
      </p>

      <Loader />
    </div>
  )
}

export default DateRangeLoader;
