import { ReactComponent as InsightsIcon } from '../../assets/insights-icon.svg';

const SystemMessage = ({ message, onViewReviewsClicked }) => {
  const formatBoldText = (text) => {
    return text.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
  };

  const formattedText = formatBoldText(message.text);

  return (
    <div className="flex max-w-[900px] w-full gap-[12px] text-white mb-[48px]">
      <InsightsIcon className="h-[32px] min-w-[32px] fill-r-blue" />

      <div className='w-full max-w-[956px]'>
        <pre
          dangerouslySetInnerHTML={{ __html: formattedText }}
          className="font-sans font-medium text-[15px] leading-[24px] text-wrap"
        >
        </pre>

        {/* <button
          onClick={e => onViewReviewsClicked(message.reviews)}
          className='text-[14px] leading-[24px] text-white my-4 font-outfit bg-r-blue px-2 py-1 font-semibold rounded-md'
        >
          View related reviews
        </button> */}
      </div>
    </div>
  )
}

export default SystemMessage;
