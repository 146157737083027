import { useState, useEffect } from "react";

import apiService from "../services/apiService";

const processResults = (results, availableNetworks, selectedRestaurant) => {
  const topicReviews = {};

  Object.keys(results).forEach((topicKey) => {
    const topicData = results[topicKey];

    topicData.forEach((topic) => {
      const { _id: { network, rating }, count } = topic;

      topicReviews[topicKey] = topicReviews[topicKey] || {};
      topicReviews[topicKey][network] = topicReviews[topicKey][network] || {};
      topicReviews[topicKey][network]['ratingMap'] = topicReviews[topicKey][network]['ratingMap'] || {};
      topicReviews[topicKey][network]['ratingMap'][rating] = topicReviews[topicKey][network]['ratingMap'][rating] || 0;
      topicReviews[topicKey][network]['ratingMap'][rating] += count;
      topicReviews[topicKey][network].total = topicReviews[topicKey][network].total || 0;
      topicReviews[topicKey][network].total += count;
    });
  });

  Object.keys(topicReviews).forEach((topicKey) => {
    const topicData = topicReviews[topicKey];

    Object.keys(topicData).forEach((networkKey) => {
      const networkData = topicData[networkKey];

      let totalReviews = 0;
      let weightedSum = 0;

      for (const rating in networkData['ratingMap']) {
        if (rating === 'total') {
          continue;
        }

        const count = networkData['ratingMap'][rating];
        totalReviews += count;
        weightedSum += Number(rating) * count;
        topicData['ratingMap'] = topicData['ratingMap'] || {};
        topicData['ratingMap'][rating] = topicData['ratingMap'][rating] || 0;
        topicData['ratingMap'][rating] += count;
        networkData.average = totalReviews ? Number(weightedSum / totalReviews).toFixed(1) : 0;
        networkData.total = totalReviews;
      }
    });

    topicData['ratingMap'] = Object.keys(topicData['ratingMap']).reduce((acc, rating) => {
      if (rating === 'total') {
        return acc;
      }

      acc.push({
        rating: Number(rating),
        count: topicData['ratingMap'][rating]
      });

      return acc;
    }, []);

    for (let i = 1; i <= 5; i++) {
      const rating = topicData['ratingMap'].find(rating => rating.rating === i);

      if (!rating) {
        topicData['ratingMap'].push({
          rating: i,
          count: 0
        });
      }
    }
  });

  topicReviews['summary'] = {};

  availableNetworks.filter(network => network !== 'instagram').forEach(network => {
    const totalReviews = selectedRestaurant?.[network]?.reviewCount?.native?.total || 0;
    const averageRating = selectedRestaurant?.[network]?.globalRating || 0;

    topicReviews['summary'][network] = {
      total: totalReviews,
      average: averageRating
    };
  });

  return topicReviews;
}

const useFetchReviewCountsbyTopic = ({ selectedRestaurant, availableNetworks, isProcessingRestaurant, startDate, cutOffDate }) => {
  const [topicReviews, setTopicReviews] = useState(null);

  const [isFetchingReviewCountsByTopic, setIsFetchingReviewCountsByTopic] = useState(false);
  const [hasFetchedReviewCountsByTopic, setHasFetchedReviewCountsByTopic] = useState(false);

  useEffect(() => {
    const fetchReviewCountsByTopic = async () => {
      try {
        setIsFetchingReviewCountsByTopic(true);

        const payload = { restaurantId: selectedRestaurant._id };

        if (startDate) payload.startDate = startDate;
        if (cutOffDate) payload.cutOffDate = cutOffDate;

        const response = await apiService.fetchReviewCountsByTopic(payload);

        const topicReviews = processResults(response.data[0], availableNetworks, selectedRestaurant);

        const summaryReviews = await apiService.fetchFormattedAverages(payload);

        topicReviews['summary'] = summaryReviews.data;

        setTopicReviews(topicReviews);
        setHasFetchedReviewCountsByTopic(true);
      } catch (error) {
        console.error("Error fetching review counts by topic:", error);
      } finally {
        setIsFetchingReviewCountsByTopic(false);
      }
    };

    if (selectedRestaurant && !isFetchingReviewCountsByTopic && !isProcessingRestaurant && !hasFetchedReviewCountsByTopic) {
      fetchReviewCountsByTopic();
    }
  }, [selectedRestaurant, isProcessingRestaurant, availableNetworks, isFetchingReviewCountsByTopic, hasFetchedReviewCountsByTopic, startDate, cutOffDate]);

  return { topicReviews, setTopicReviews, setHasFetchedReviewCountsByTopic };
};

export default useFetchReviewCountsbyTopic;