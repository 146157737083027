function isProcessingRestaurant({ selectedRestaurant, networks }) {
  const isProcessingRestaurant = networks.filter(network => network !== 'instagram').reduce((acc, network) => {
    if (acc) return acc;

    if (!selectedRestaurant) return false;

    if (!selectedRestaurant?.[network]?.id) return false;

    if (!selectedRestaurant?.analysis?.summaryUpdatedOn || !selectedRestaurant?.analysis?.topicsUpdatedOn) return true;

    if (selectedRestaurant?.[network]?.isProcessingResults) return true;

    return acc;
  }, false);

  return { isProcessingRestaurant }
}

export default isProcessingRestaurant;
