import { createContext, useState, useEffect } from 'react';
import { useLocation, useSearchParams, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';

import apiService from '../services/apiService';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(null);
  const [user, setUser] = useState(null);
  const [isFetchingUser, setIsFetchingUser] = useState(false);
  const [hasFetchedUser, setHasFetchedUser] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isUpgradeToProOpen, setIsUpgradeToProOpen] = useState(false);

  const [searchParams] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();

  const acceptablePlanNames = ['Pro (Monthly)', 'Pro (Yearly)'];

  useEffect(() => {
    const fetchUser = async () => {
      setLoading(true);
      setIsFetchingUser(true);
      const token = localStorage.getItem('token');

      try {
        const response = await apiService.fetchUserFromToken({ token });

        setUser(response.data);
        setIsFetchingUser(false);
        setHasFetchedUser(true);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        setIsFetchingUser(false);
        navigate('/login');
        toast.error(error?.response?.data?.message || 'Your token expired, please login again.');
      }
    };

    const excludedPaths = ['/login', '/register', '/trials'];

    if (!excludedPaths.includes(location.pathname)) {
      const paramsToken = searchParams.get('token');

      if (paramsToken) {
        localStorage.setItem('token', paramsToken);
        setToken(paramsToken);
        setLoading(false);
        return navigate('/dashboard');
      }

      const storedToken = localStorage.getItem("token");

      setToken(storedToken);
      setLoading(false);

      if (!user && !isFetchingUser && !hasFetchedUser) fetchUser();
    }
  }, [user, token, navigate, searchParams]);

  const isSubscriptionCanceled = user && user.subscription.status === 'canceled';
  const isSubscriptionExpired = user && new Date(user?.subscription?.current_period_end * 1000) < new Date();

  return (
    <AuthContext.Provider value={{
      token,
      setToken,
      user,
      setUser,
      setHasFetchedUser,
      loading,
      setLoading,
      isUpgradeToProOpen,
      setIsUpgradeToProOpen,
      isProUser: user && acceptablePlanNames.includes(user.subscription.planName),
      isSubscriptionActive: !isSubscriptionCanceled && !isSubscriptionExpired
    }}
    >
      {children}
    </AuthContext.Provider>
  );
}
