import { createContext, useContext, useEffect, useState } from "react";

import { AuthContext } from "./AuthContext";

import { SEARCH_NETWORKS } from "../constants";

import apiService from "../services/apiService";

import useFetchReviewCountsbyTopic from "../hooks/useFetchReviewCountsbyTopic";
import useInterval from "../hooks/useInterval";
import useRatingMaps from "../hooks/useRatingMaps";
import useSnapshots from "../hooks/useSnapshots";
import useIsProcessingRestaurant from "../hooks/useIsProcessingRestaurant";
import useIsProcessingInstagramResults from "../hooks/useIsProcessingInstagramResults";

export const RestaurantContext = createContext();

export const RestaurantProvider = ({ children }) => {
  const { user, setUser, token } = useContext(AuthContext);

  const [selectedRestaurant, setSelectedRestaurant] = useState(null);

  const [userRestaurant, setUserRestaurant] = useState(null);
  const [isLoadingRestaurant, setIsLoadingRestaurant] = useState(false);
  const [isFindingRestaurant, setIsFindingRestaurant] = useState(false);
  const [hasFetchedRestaurant, setHasFetchedRestaurant] = useState(false);

  const [selectedSnapshot, setSelectedSnapshot] = useState(null);

  const [instagramCommentCount, setInstagramCommentCount] = useState(null);
  const [isFetchingInstagramCommentCount, setIsFetchingInstagramCommentCount] = useState(false);
  const [hasFetchedInstagramCommentCount, setHasFetchedInstagramCommentCount] = useState(false);

  const [selectedDateRange, setSelectedDateRange] = useState([null, null]);
  const [minSelectorDate, setMinSelectorDate] = useState(null);
  const [maxSelectorDate, setMaxSelectorDate] = useState(null);

  const [opportunities, setOpportunities] = useState(null);

  const networks = SEARCH_NETWORKS.map(network => network.value);

  const availableNetworks = networks.reduce((acc, network) => {
    if (!selectedRestaurant) return acc;

    if (network === 'instagram' && selectedRestaurant?.instagram?.posts) {
      return [...acc, 'instagram'];
    } else if (selectedRestaurant?.[network]?.id) {
      return [...acc, network];
    }

    return acc;
  }, []);

  useEffect(() => {
    const fetchRestaurant = async () => {
      const response = await apiService.fetchUserRestaurant({ userId: user._id });

      setUserRestaurant(response.data);
      setSelectedRestaurant(response.data);

      const oldestReviewDate = response.data?.oldestReviewDate ? new Date(response.data?.oldestReviewDate) : null;
      const latestReviewDate = response.data?.latestReviewDate ? new Date(response.data?.latestReviewDate) : null;

      setSelectedDateRange([oldestReviewDate, latestReviewDate]);
      setMinSelectorDate(oldestReviewDate);
      setMaxSelectorDate(latestReviewDate);

      setIsLoadingRestaurant(false);
      setHasFetchedRestaurant(true);
    };

    if (user && !userRestaurant && !isLoadingRestaurant && !hasFetchedRestaurant) {
      setIsLoadingRestaurant(true);
      fetchRestaurant();
    }
  }, [user, userRestaurant, isLoadingRestaurant, hasFetchedRestaurant]);

  const { isProcessingRestaurant } = useIsProcessingRestaurant({ selectedRestaurant, networks });
  const { isProcessingInstagramResults } = useIsProcessingInstagramResults({ selectedRestaurant });

  const { topicReviews, setHasFetchedReviewCountsByTopic } = useFetchReviewCountsbyTopic({
    selectedRestaurant,
    availableNetworks,
    isProcessingRestaurant,
    startDate: selectedDateRange[0],
    cutOffDate: selectedDateRange[1],
  });

  const { restaurantSelectOptions, setHasFetchedSnapshots } = useSnapshots({ user, userRestaurant, token, setUser });

  const { ratingMaps, setRatingMaps, setHasFetchedRatingMaps } = useRatingMaps({
    restaurantId: selectedRestaurant?._id,
    startDate: selectedDateRange[0],
    cutOffDate: selectedDateRange[1],
    isProcessingRestaurant
  });

  useInterval(() => {
    const fetchRestaurant = async () => {
      const { data: fetchedRestaurant } = await apiService.fetchRestaurant({ restaurantId: selectedRestaurant._id });

      if (user._id === fetchedRestaurant.user) {
        setUserRestaurant(fetchedRestaurant);
      }

      setSelectedRestaurant(fetchedRestaurant);
    }

    fetchRestaurant();
  }, (isProcessingRestaurant || isProcessingInstagramResults) ? 10 * 1000 : null);

  const resetLoadedRestaurant = () => {
    setRatingMaps(null);
    setHasFetchedRatingMaps(false);
    setOpportunities(null);
    setInstagramCommentCount(null);
    setIsFetchingInstagramCommentCount(false);
    setHasFetchedInstagramCommentCount(false);
    setHasFetchedReviewCountsByTopic(false);
  }

  const isGeneratingSummaries = !selectedRestaurant?.analysis?.summaryUpdatedOn || !selectedRestaurant?.analysis?.topicsUpdatedOn;

  return (
    <RestaurantContext.Provider value={{
      restaurant: selectedRestaurant,
      setRestaurant: setSelectedRestaurant,
      setUserRestaurant,
      isLoadingRestaurant,
      hasFetchedRestaurant,

      selectedSnapshot,
      setSelectedSnapshot,
      setHasFetchedSnapshots,

      isProcessingRestaurant,
      isGeneratingSummaries,
      isFindingRestaurant,
      setIsFindingRestaurant,

      searchNetworks: SEARCH_NETWORKS,
      availableNetworks,
      instagramCommentCount,

      ratingMaps,
      setRatingMaps,
      setHasFetchedRatingMaps,

      topicReviewCounts: topicReviews,
      setHasFetchedReviewCountsByTopic,

      opportunities,
      setOpportunities,

      restaurantSelectOptions,

      isFetchingInstagramCommentCount,

      selectedDateRange,
      setSelectedDateRange,

      minSelectorDate,
      setMinSelectorDate,
      maxSelectorDate,
      setMaxSelectorDate,

      resetLoadedRestaurant
    }}>
      {children}
    </RestaurantContext.Provider>
  );
};