const userPlans = [
  {
    name: 'Basic',
    priceMonthly: 24,
    priceYearly: 899,
    priceYearlyPerMonth: 12,
    idMonthly: 'price_1Qh2m9AIeqCVMOVBNIfxUXiE',
    idYearly: 'price_1Qh2oaAIeqCVMOVBBxMdq9GR',
    isMostPopular: false,
    availableNetworks: [
      'Google',
      'GrubHub',
      'DoorDash',
      'Yelp',
      'TripAdvisor',
      'UberEats (coming soon)',
    ],
    reviewSegmentation: [
      'Food',
      'Drinks',
      'Service',
      'Price'
    ],
    additions: [
      'Restaurant Spy',
      'Advanced Search UI',
      'Keyword Search',
      'Browse All Reviews',
    ]
  },
  {
    name: 'Pro',
    priceMonthly: 98,
    priceYearly: 2499,
    priceYearlyPerMonth: 49,
    idMonthly: 'price_1QggnhAIeqCVMOVBoWRcRDH9',
    idYearly: 'price_1QgglDAIeqCVMOVB6FsUFT6Z',
    isMostPopular: true,
    availableNetworks: [
      'Google',
      'GrubHub',
      'DoorDash',
      'Yelp',
      'TripAdvisor',
      'UberEats (coming soon)',
    ],
    reviewSegmentation: [
      'Food',
      'Drinks',
      'Service',
      'Price'
    ],
    additions: [
      'Restaurant Spy Advanced',
      "1 Free Advanced Spy Report Included",
      'Advanced Search UI',
      'Keyword Search',
      'Browse All Reviews',
      'Find Opportunities with AI',
      'Q/A with AI'
    ]
  }
];

export default userPlans;
