import { ReactComponent as RocketIcon } from '../assets/rocket-icon.svg';

const UpgradeToPro = ({ message, onUpgradeClicked, onCancelClicked }) => {
  return (
    <div className="flex flex-col items-center justify-center p-[32px] bg-[#1D2839] w-[422px] rounded-lg">
      <RocketIcon className="mb-[24px]" />

      <p className='text-[24px] font-medium text-white mb-2 text-center'>Upgrade to Pro</p>
      <p className='font-medium text-[18px] text-[#9CABC4] mb-[24px] text-center'>
        {message}
      </p>

      <div className='grid grid-cols-2 gap-2 w-full'>
        <button onClick={onUpgradeClicked} className='flex items-center justify-center w-full h-[48px] bg-r-blue rounded-md text-white font-semibold'>
          Upgrade
        </button>

        <button
          className='flex items-center justify-center w-full h-[48px] bg-[#394558] rounded-md text-white font-semibold'
          onClick={onCancelClicked}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default UpgradeToPro;
