import { useContext } from 'react';
import { Link } from 'react-router-dom';

import { RestaurantContext } from '../RestaurantContext';

import { ReactComponent as SearchIcon } from '../../assets/search-icon.svg';
import { ReactComponent as InsightsIcon } from '../../assets/insights-icon.svg';

import analysisOptions from '../../data/analysisOptions';

import NetworkListItem from './NetworkListItem';
import RatingMap from './RatingMap';

const ReviewSummary = ({ currentAnalysis, overviewText }) => {
  const { restaurant, availableNetworks, topicReviewCounts, ratingMaps } = useContext(RestaurantContext);

  return (
    <div className="p-[16px] md:p-[32px] bg-[#2a323f] md:max-h-[100vh] md:overflow-y-auto">
      <div className="bg-[#202731] rounded-lg p-[24px] mb-4 md:min-h-[227px]">
        <h2 className="text-white font-semibold text-[21px]">{analysisOptions.find(a => a.value === currentAnalysis).label}</h2>
        <p className="text-[#B0B8C3] mb-1 text text-[16px]">{overviewText}</p>
      </div>

      <div className="flex flex-col mb-4 gap-2">
        {availableNetworks.filter(network => network !== 'instagram' & restaurant[network]?.reviewCount?.native?.total > 0).map(network => (
          <NetworkListItem
            networkSlug={network}
            totalReviews={topicReviewCounts?.[currentAnalysis]?.[network]?.total || 0}
            averageRating={topicReviewCounts?.[currentAnalysis]?.[network]?.average ? Number(topicReviewCounts?.[currentAnalysis]?.[network]?.average).toFixed(1) : 0}
            key={network}
          />
        ))}
      </div>

      {ratingMaps && <RatingMap ratingMap={ratingMaps[currentAnalysis]} />}

      <div className="flex flex-col md:grid md:grid-cols-2 gap-4">
        <Link to="/search" className="h-[64px] bg-r-blue rounded-lg flex items-center justify-center font-bold text-[15px] text-white gap-2">
          <SearchIcon className="stroke-white h-[16px]" />
          <span>Browse All Reviews</span>
        </Link>

        <Link to="/insights" className="h-[64px] bg-r-blue rounded-lg flex items-center justify-center font-bold text-[15px] text-white gap-2">
          <InsightsIcon className="fill-white h-[14px]" />
          <span>Ask a Question</span>
        </Link>
      </div>
    </div>
  )
}

export default ReviewSummary;
