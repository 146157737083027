import { useEffect, useState, useContext } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

import { AuthContext } from "./AuthContext";
import { RestaurantContext } from "./RestaurantContext";

import ReviewSummary from "./Dashboard/ReviewSummary";
import RestaurantInfo from "./Dashboard/RestaurantInfo";
import DashboardLoader from "./DashboardLoader";
import ReviewSummaryLoader from "./Dashboard/ReviewSummaryLoader";
import RestaurantIndicator from "./RestaurantIndicator";

import apiService from "../services/apiService";
import DateRangeLoader from "./Dashboard/DateRangeLoader";

const UserDashboard = ({ restaurant, isGeneratingSummaries }) => {
  const { user, isProUser, setIsUpgradeToProOpen } = useContext(AuthContext);

  const {
    setRestaurant,
    selectedSnapshot,
    selectedDateRange,
    setSelectedDateRange,
    setSelectedSnapshot,
    setHasFetchedRatingMaps,
    resetLoadedRestaurant,
    isProcessingRestaurant,
    setHasFetchedReviewCountsByTopic
  } = useContext(RestaurantContext);

  const [currentAnalysis, setCurrentAnalysis] = useState('summary');
  const [overviewText, setOverviewText] = useState(restaurant?.analysis?.['summary']);

  const [isRegeneratingSummaries, setIsRegeneratingSummaries] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    setOverviewText(restaurant?.analysis?.[currentAnalysis]);
  }, [currentAnalysis, restaurant]);

  const handleInstagramConnectClicked = () => {
    isProUser ? navigate('/find-instagram') : setIsUpgradeToProOpen(true);
  }

  const handleOpportunitiesClicked = () => {
    isProUser ? navigate('/opportunities') : setIsUpgradeToProOpen(true);
  }

  const handleDateRangeChange = async (dateRange) => {
    setSelectedDateRange(dateRange);
    setIsRegeneratingSummaries(true);
    setHasFetchedRatingMaps(false);
    setHasFetchedReviewCountsByTopic(false);

    const payload = {
      restaurantId: restaurant._id
    };

    if (dateRange?.[0]) {
      payload.startDate = new Date(dateRange[0]);
    }

    if (dateRange?.[1]) {
      payload.cutOffDate = new Date(dateRange[1]);
    }

    try {
      const requests = [apiService.generateSummariesByPeriod(payload)]

      const [{ data: summaries }] = await Promise.all(requests);

      const updatedRestaurant = {
        ...restaurant,
        analysis: {
          ...restaurant.analysis,
          summary: summaries.general,
          food: summaries.food,
          drink: summaries.drink,
          service: summaries.service,
          price: summaries.price
        }
      }

      setRestaurant(updatedRestaurant);
      setIsRegeneratingSummaries(false);
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 flex-grow pb-[16px] md:pb-0">
      <RestaurantInfo
        currentAnalysis={currentAnalysis}
        user={user}
        restaurant={restaurant}
        selectedDateRange={selectedDateRange}
        setSelectedDateRange={setSelectedDateRange}
        onDateRangeChange={handleDateRangeChange}
        setRestaurant={setRestaurant}
        selectedSnapshot={selectedSnapshot}
        setSelectedSnapshot={setSelectedSnapshot}
        resetLoadedRestaurant={resetLoadedRestaurant}
        onAnalysisClicked={analysis => setCurrentAnalysis(analysis)}
        onOpportunitiesClicked={handleOpportunitiesClicked}
        isRegeneratingSummaries={isRegeneratingSummaries}
      />

      {isProcessingRestaurant || isGeneratingSummaries
        ? <ReviewSummaryLoader />
        : !isRegeneratingSummaries
          ? <ReviewSummary
            currentAnalysis={currentAnalysis}
            overviewText={overviewText}
            onInstagramConnectClicked={handleInstagramConnectClicked}
          />
          : <DateRangeLoader />
      }
    </div>
  )
}

function Dashboard() {
  const { user, isSubscriptionActive } = useContext(AuthContext);

  const {
    restaurant,
    isLoadingRestaurant,
    hasFetchedRestaurant,
    isGeneratingSummaries
  } = useContext(RestaurantContext);

  if (!restaurant && hasFetchedRestaurant) {
    return <Navigate to="/find-restaurant" replace />;
  }

  if (!isSubscriptionActive) {
    return <Navigate to="/profile" replace />;
  }

  return (
    <>
      <Helmet>
        <title>Dashboard | Restaurant Report</title>
      </Helmet>

      {
        isLoadingRestaurant || !user
          ? <DashboardLoader />
          : <UserDashboard
            restaurant={restaurant}
            isGeneratingSummaries={isGeneratingSummaries}
          />
      }

      <RestaurantIndicator />
    </>
  );
}

export default Dashboard;