import { useState, useContext } from 'react';
import { AuthContext } from './AuthContext';
import { Link, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import apiService from '../services/apiService';

import { API_URL } from '../constants';

import { ReactComponent as GoogleLogo } from '../assets/google-icon.svg';

const LoginForm = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState(null);

  const { setToken } = useContext(AuthContext);

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await apiService.login({ email, password });

      setToken(response.data.token);
      localStorage.setItem("token", response.data.token);
      navigate("/dashboard");
    } catch (error) {
      console.error("Authentication failed:", error);

      setToken(null);

      localStorage.removeItem("token");

      if (error.response && error.response.data) {
        setErrorMessage(error.response.data);
      } else {
        setErrorMessage("An unexpected error occurred. Please try again.");
      }
    }
  }

  return (
    <div className='flex flex-col items-center justify-between flex-grow min-h-screen p-[16px]'>
      <Helmet>
        <title>Login | Restaurant Report</title>
      </Helmet>

      <h1 className='text-[25px] font-semibold gap-1 flex items-center justify-center mt-[50px]'>
        <span className='text-white'>Restaurant</span>
        <span className='text-r-blue'>Report</span>
      </h1>

      <div className='flex flex-col items-center justify-center flex-grow min-w-full'>
        <h2 className='text-center text-[32px] font-medium text-white mb-[24px]'>Log In</h2>

        {errorMessage && <div style={{ color: "red" }}>{errorMessage}</div>}{" "}

        <form onSubmit={handleSubmit} className='flex flex-col w-full md:w-[479px] mb-[50px]'>
          <label className='flex flex-col gap-[16px] mb-[24px]'>
            <span className='text-white font-medium text-[17px]'>Your E-mail</span>

            <input
              type='email'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email"
              className='bg-r-gray-light h-[72px] text-white p-[24px] focus:border-r-blue border-r-gray-light rounded-md'
            />
          </label>

          <label className='flex flex-col gap-[16px] mb-[16px]'>
            <span className='text-white font-medium text-[17px]'>Password</span>

            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Password"
              className='bg-r-gray-light h-[72px] text-white p-[24px] focus:border-r-blue border-r-gray-light rounded-md'
            />
          </label>

          <div className='flex items-center justify-between mb-[32px]'>
            <label className='flex items-center'>
              <input type="checkbox" className='mr-[8px] bg-transparent border border-[#B9BECF] checked:bg-r-blue checked:focus:bg-r-blue focus:ring-0 rounded-sm hover:bg-r-blue checked:hover:bg-r-blue focus:outline-none hover:ring-0 focus:border-none' />

              <span className='text-[#929AA6] text-[16px]'>Remember me</span>
            </label>

            <Link to="/forgot-password" className='font-medium text-[#C0C7D1] text-[16px]'>
              Forgot password?
            </Link>
          </div>

          <button type="submit" disabled={!email || !password} className='bg-r-blue h-[56px] rounded-md text-white font-semibold mb-[16px] disabled:bg-[#565B6D]'>Log In</button>

          {/* <a
            href={`${API_URL}/users/login/google`}
            className='bg-transparent border border-r-blue text-white font-semibold p-2 rounded-md text-center flex items-center justify-center gap-2 h-[56px] mb-[32px]'
          >
            <GoogleLogo /> Log In with Google
          </a> */}

          <p className='text-center text-[#A2A9BF] font-medium'>
            Don't have an account? <a href="https://www.restaurantreport.ai?section=pricing" className='text-r-blue'>Sign Up</a>
          </p>
        </form>
      </div>
    </div>
  );
}

export default LoginForm;
