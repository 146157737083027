import { useEffect, useState } from "react";

import apiService from "../services/apiService";

function useRatingMaps({ restaurantId, startDate, cutOffDate, isProcessingRestaurant }) {
  const [ratingMaps, setRatingMaps] = useState(null);
  const [isFetchingRatingMaps, setIsFetchingRatingMaps] = useState(false);
  const [hasFetchedRatingMaps, setHasFetchedRatingMaps] = useState(false);

  useEffect(() => {
    const fetchRatingMaps = async ({ restaurantId }) => {
      setIsFetchingRatingMaps(true);

      try {
        const payload = { restaurantId };

        if (startDate) payload.startDate = startDate;
        if (cutOffDate) payload.cutOffDate = cutOffDate;

        const { data: ratingMaps } = await apiService.fetchRatingMaps(payload);

        setRatingMaps(ratingMaps);
        setHasFetchedRatingMaps(true);
      } catch (error) {
        console.error(error);
      } finally {
        setIsFetchingRatingMaps(false);
      }
    }

    if (restaurantId && !isFetchingRatingMaps && !hasFetchedRatingMaps && !isProcessingRestaurant) {
      fetchRatingMaps({ restaurantId });
    }
  }, [restaurantId, startDate, cutOffDate, isFetchingRatingMaps, hasFetchedRatingMaps, isProcessingRestaurant]);

  return {
    ratingMaps,
    setRatingMaps,
    hasFetchedRatingMaps,
    setHasFetchedRatingMaps
  };
}

export default useRatingMaps;
