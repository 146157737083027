import { API_URL } from "../constants";

import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: API_URL
});

const fetchUserFromToken = async ({ token }) => {
  const response = await axiosInstance.post('/users/info-from-token', { token });

  return response;
}

const updateUser = async ({ userId, updateData }) => {
  const response = await axiosInstance.put(`/users/${userId}`, updateData);

  return response;
}

const findRestaurant = async ({ name, address }) => {
  const response = await axiosInstance.post(`/places/find-restaurant`, {
    name,
    address,
    networks: ['google']
  });

  return response;
}

const startRestaurantAnalysis = async ({ googleResult, userId, isSnapshot = false }) => {
  const response = await axiosInstance.post(`/restaurant/jobs/start-analysis`, {
    googleResult,
    userId,
    isSnapshot
  });

  return response;
}

const fetchUserRestaurant = async ({ userId }) => {
  const response = await axiosInstance.post(`/users/restaurant`, { userId });

  return response;
}

const fetchRestaurant = async ({ restaurantId }) => {
  const response = await axiosInstance.get(`/restaurant/${restaurantId}`);

  return response;
}

const updateRestaurant = async ({ restaurantId, updateData }) => {
  const response = await axiosInstance.put(`/restaurant/${restaurantId}`, updateData);

  return response;
}

const fetchUserSnapshots = async ({ userId }) => {
  const response = await axiosInstance.get(`/users/${userId}/snapshots`, { userId });

  return response;
}

const searchReviews = async ({ restaurantId, text, networks, topics, ratings, sort, sortDirection, page, startDate, cutOffDate }) => {
  const payload = {
    restaurantId,
    text,
    networks,
    topics,
    ratings,
    sort,
    sortDirection,
    page
  };

  if (startDate) {
    payload.startDate = startDate;
  }

  if (cutOffDate) {
    payload.cutOffDate = cutOffDate;
  }

  const response = await axiosInstance.post(`/reviews/search`, payload);

  return response;
}

const generateInsightsResponse = async ({ restaurantId, question }) => {
  const response = await axiosInstance.post(`/reviews/generate-insights`, { restaurantId, question });

  return response;
}

const fetchRestaurantOpportunities = async ({ restaurantId }) => {
  const response = await axiosInstance.get(`/restaurant/${restaurantId}/opportunities`);

  return response;
}

const fetchRatingMaps = async ({ restaurantId, startDate, cutOffDate }) => {
  const payload = {};

  if (startDate) payload.startDate = startDate;
  if (cutOffDate) payload.cutOffDate = cutOffDate;

  const response = await axiosInstance.post(`/restaurant/${restaurantId}/rating-maps-by-topic`, payload);

  return response;
}

const fetchReviewRatingsMap = async ({ restaurantId, startDate, cutOffDate }) => {
  const payload = {};

  if (startDate) {
    payload.startDate = startDate;
  }

  if (cutOffDate) {
    payload.cutOffDate = cutOffDate;
  }

  const response = await axiosInstance.post(`/reviews/ratings-map/${restaurantId}`, payload);

  return response;
}

const fetchFormattedAverages = async ({ restaurantId, startDate, cutOffDate }) => {
  const payload = {
    restaurantId
  };

  if (startDate) {
    payload.startDate = startDate;
  }

  if (cutOffDate) {
    payload.cutOffDate = cutOffDate;
  }

  const response = await axiosInstance.post(`/reviews/network-data-by-date`, payload);

  return response;
}

const fetchReviewCountsByTopic = async ({ restaurantId, startDate, cutOffDate }) => {
  const payload = {
    restaurantId
  }

  if (startDate) {
    payload.startDate = startDate;
  }

  if (cutOffDate) {
    payload.cutOffDate = cutOffDate;
  }

  const response = await axiosInstance.post(`/restaurant/review-count-by-topic`, payload);

  return response;
};

const generateSummariesByPeriod = async ({ restaurantId, startDate, cutOffDate }) => {
  const response = await axiosInstance.post(`/restaurant/generate-summaries-by-period`, { restaurantId, startDate, cutOffDate });

  return response;
};

const login = async ({ email, password }) => {
  const response = await axiosInstance.post(`/auth/login`, { email, password });

  return response;
}

const register = async ({ firstName, lastName, email, password, stripeCheckoutId }) => {
  const response = await axiosInstance.post(`/auth/register`, {
    firstName,
    lastName,
    email,
    password,
    stripeCheckoutId
  });

  return response;
}

const updateSubscription = async ({ userId, priceId }) => {
  const response = await axiosInstance.post(`/users/update-subscription`, { userId, priceId });

  return response;
}

const cancelSubscription = async ({ userId, subscriptionId }) => {
  const response = await axiosInstance.post(`/users/cancel-subscription`, { userId, subscriptionId });

  return response;
}

const revokeSubscriptionCancelation = async ({ userId, subscriptionId }) => {
  const response = await axiosInstance.post(`/users/revoke-subscription-cancelation`, { userId, subscriptionId });

  return response;
};

const updateCanceledSubscription = async ({ userId, priceId }) => {
  const response = await axiosInstance.post(`/users/update-canceled-subscription`, { userId, priceId });

  return response;
};

const generateSpyCheckoutSession = async ({ userId }) => {
  const response = await axiosInstance.post(`/users/create-spy-checkout-session`, { userId });

  return response;
};

const fetchForgotPasswordRequest = async ({ forgotPasswordRequestId }) => {
  const response = await axiosInstance.get(`/users/forgot-password/${forgotPasswordRequestId}`);

  return response;
};

const createForgotPasswordRequest = async ({ userEmail }) => {
  const response = await axiosInstance.post(`/users/forgot-password`, { email: userEmail });

  return response;
};

const resetPassword = async ({ forgotPasswordRequestId, newPassword }) => {
  const response = await axiosInstance.post(`/users/reset-password`, {
    forgotPasswordRequestId,
    newPassword
  });

  return response;
}

const fetchInstagramCommentCount = async ({ restaurantId }) => {
  const response = await axiosInstance.get(`/restaurant/${restaurantId}/instagram/comment-count`);

  return response;
}

const findInstagramProfile = async ({ slug }) => {
  const response = await axiosInstance.post(`/restaurant/instagram/find-profile`, { slug });

  return response;
}

const startInstagramPostFetchingJob = async ({ restaurantId, instagramSlug }) => {
  const response = await axiosInstance.post(`/restaurant/jobs/instagram/fetch-posts`, {
    instagramSlug,
    restaurantId
  });

  return response;
}

const deleteUser = async ({ userId }) => {
  const response = await axiosInstance.post(`/users/${userId}/delete`);

  return response;
}

const registerTrialUser = async ({ email, firstName, lastName, password, promoCode }) => {
  const response = await axiosInstance.post(`/users/register-trial`, { email, firstName, lastName, password, promoCode });

  return response;
}

const apiService = {
  fetchUserFromToken,
  updateUser,
  findRestaurant,
  startRestaurantAnalysis,
  fetchUserRestaurant,
  fetchRestaurant,
  updateRestaurant,
  fetchUserSnapshots,
  searchReviews,
  generateInsightsResponse,
  fetchRestaurantOpportunities,
  fetchReviewRatingsMap,
  fetchRatingMaps,
  fetchFormattedAverages,
  fetchReviewCountsByTopic,
  generateSummariesByPeriod,
  login,
  register,
  updateSubscription,
  cancelSubscription,
  revokeSubscriptionCancelation,
  updateCanceledSubscription,
  generateSpyCheckoutSession,
  fetchForgotPasswordRequest,
  createForgotPasswordRequest,
  resetPassword,
  fetchInstagramCommentCount,
  findInstagramProfile,
  startInstagramPostFetchingJob,
  deleteUser,
  registerTrialUser
}

export default apiService;
