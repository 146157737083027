import { ReactComponent as CloseIcon } from '../assets/close-icon.svg';

const TermsOfService = ({ onCloseClicked }) => {
  return (
    <div className="w-[900px] max-h-[80%] bg-[#1D2839] p-[32px] rounded-lg overflow-scroll">
      <div className="flex justify-between items-center mb-[8px]">
        <p className="font-medium text-[30px] text-white"></p>

        <button className="text-[#9CABC4]" onClick={onCloseClicked}>
          <CloseIcon className="stroke-[#9CABC4] hover:stroke-[#18A6C5]" />
        </button>
      </div>

      <div className="text-white">
        <div className='text-center mb-8'>
          <h1 className="text-[20px] font-semibold mb-2">Terms and Conditions for RestaurantReport.ai</h1>
          <p className="text-[14px] font-medium">Effective Date: 10/14/2024</p>
        </div>

        <h2 className="text-[20px] font-semibold mb-2">1. Acceptance of Terms</h2>
        <p className="text-[14px] font-medium">
          Welcome to <em>RestaurantReport.ai</em> (“we,” “us,” or “our”). These Terms and Conditions (“Terms”)
          govern your access to and use of the website located at www.restaurantreport.ai (“the Website”)
          and the associated services we provide (collectively, “the Service”). By accessing or using our
          Service, you agree to comply with these Terms in their entirety. If you do not agree to these
          Terms, you may not access or use the Service.
        </p>
        <p className="text-[14px] font-medium">
          We reserve the right to modify these Terms at any time, and any such modifications will be
          effective immediately upon posting. Your continued use of the Service following the posting of
          revised Terms constitutes your acceptance of the updated Terms.
        </p>

        <h2 className="text-[20px] font-semibold mt-4 mb-2">2. Use of the Service</h2>
        <p className="text-[14px] font-medium">
          <em>RestaurantReport.ai</em> provides a platform for analyzing customer reviews from various sources
          to generate insights for restaurant owners. By using our Service, you agree to the following
          conditions:
        </p>
        <ul className="list-disc pl-5 my-3">
          <li className="text-[14px] font-medium">
            The insights provided are based on data gathered from customer reviews and are for
            informational purposes only. These insights are not intended to be definitive business advice or
            guarantee any specific outcomes.
          </li>
          <li className="text-[14px] font-medium">
            You are responsible for how you apply the insights to your business operations.
          </li>
        </ul>

        <h2 className="text-[20px] font-semibold mt-4 mb-2">3. Account Registration and Security</h2>
        <p className="text-[14px] font-medium">
          To use the Service, you must create an account with us. By registering an account, you agree to
          provide accurate, current, and complete information, and to update this information as
          necessary. You are solely responsible for maintaining the confidentiality of your account
          credentials and for any activities or actions taken under your account. If you become aware of
          any unauthorized use of your account, you must notify us immediately.
        </p>
        <p className="text-[14px] font-medium">
          We reserve the right to suspend or terminate your account at any time for any reason, including
          if you violate these Terms.
        </p>

        <h2 className="text-[20px] font-semibold mt-4 mb-2">4. Payment Terms</h2>
        <p className="text-[14px] font-medium">
          We offer the following subscription and payment options for the Service:
        </p>
        <ul className="list-disc pl-5 my-3">
          <li className="text-[14px] font-medium">Monthly Subscription</li>
          <li className="text-[14px] font-medium">Annual Subscription</li>
          <li className="text-[14px] font-medium">One-Time Payment for a Spy Report</li>
        </ul>
        <p className="text-[14px] font-medium">
          By selecting a subscription or one-time payment option, you authorize us to charge your
          provided payment method for the applicable fees. All payments are non-refundable, as costs
          are incurred upon account creation to generate and analyze data using our AI technology. You
          may cancel your subscription at any time; however, such cancellation will be effective at the end
          of the current billing cycle. No partial refunds will be provided for cancellations made before the
          end of the billing period.
        </p>
        <p className="text-[14px] font-medium">We accept payment by credit or debit card only.</p>

        <h2 className="text-[20px] font-semibold mt-4 mb-2">5. Data Usage and Privacy</h2>
        <p className="text-[14px] font-medium">
          We collect and process customer review data solely for the purposes of providing insights to
          restaurant owners and improving our services. You acknowledge that:
        </p>
        <ul className="list-disc pl-5 my-3">
          <li className="text-[14px] font-medium">
            The data we analyze is publicly available information sourced from third-party review
            platforms.
          </li>
          <li className="text-[14px] font-medium">
            We will not share your data or insights with third parties except as required by law or as part of
            our internal efforts to improve the Service.
          </li>
        </ul>
        <p className="text-[14px] font-medium">
          Any personal information you provide to us, including during account registration, will be used
          solely for account management, communication, and product updates. For more details on how
          we handle your personal information, please refer to our Privacy Policy.
        </p>

        <h2 className="text-[20px] font-semibold mt-4 mb-2">6. Intellectual Property Rights</h2>
        <p className="text-[14px] font-medium">
          All content, materials, and services provided on the Website, including but not limited to text,
          software, graphics, and logos, are owned by <em>RestaurantReport.ai</em> and are protected by
          applicable intellectual property laws. You agree that you are granted a limited, non-exclusive,
          non-transferable right to access and use the Service solely for your internal business purposes.
          You are not permitted to reproduce, distribute, modify, or create derivative works of any portion
          of the Service or our intellectual property without prior written consent. All rights not expressly
          granted under these Terms are reserved by us.
        </p>

        <h2 className="text-[20px] font-semibold mt-4 mb-2">7. Limitation of Liability</h2>
        <p className="text-[14px] font-medium">
          You acknowledge and agree that the Service, including the AI-generated insights, is provided on
          an “as is” and “as available” basis, without any warranties of any kind, express or implied. We
          do not guarantee the accuracy, completeness, or reliability of the insights, and you use them at
          your own risk.
        </p>
        <p className="text-[14px] font-medium">
          To the maximum extent permitted by applicable law, <em>RestaurantReport.ai</em> and its affiliates,
          directors, officers, employees, and agents shall not be liable for any direct, indirect, incidental,
          consequential, or special damages arising out of or in any way connected with your use of the
          Service, even if we have been advised of the possibility of such damages.
        </p>

        <h2 className="text-[20px] font-semibold mt-4 mb-2">8. Indemnification</h2>
        <p className="text-[14px] font-medium">
          You agree to indemnify, defend, and hold harmless <em>RestaurantReport.ai</em> and its affiliates,
          directors, officers, employees, and agents from and against any claims, liabilities, damages,
          losses, and expenses, including reasonable legal fees, arising out of or in connection with your
          use of the Service, your violation of these Terms, or your infringement of any intellectual
          property or other right of any person or entity.
        </p>

        <h2 className="text-[20px] font-semibold mt-4 mb-2">9. Termination</h2>
        <p className="text-[14px] font-medium">
          We reserve the right to terminate or suspend your access to the Service, without notice, for
          conduct that we believe violates these Terms or is harmful to our interests or the interests of
          others.
        </p>
        <p className="text-[14px] font-medium">
          Upon termination, you will no longer have access to the Service, and any outstanding payments
          will remain due.
        </p>

        <h2 className="text-[20px] font-semibold mt-4 mb-2">10. Governing Law and Dispute Resolution</h2>
        <p className="text-[14px] font-medium">
          These Terms are governed by and construed in accordance with the laws of the state of
          Pennsylvania, without regard to its conflict of law principles. Any dispute arising out of or related
          to these Terms or the use of the Service will be resolved through binding arbitration in
          accordance with the rules of the American Arbitration Association.
        </p>

        <h2 className="text-[20px] font-semibold mt-4 mb-2">11. Entire Agreement</h2>
        <p className="text-[14px] font-medium">
          These Terms constitute the entire agreement between you and <em>RestaurantReport.ai</em> regarding
          your use of the Service and supersede any prior agreements, communications, or
          understandings, whether written or oral, relating to the subject matter hereof.
        </p>

        <h2 className="text-[20px] font-semibold mt-4 mb-2">12. Contact Information</h2>
        <p className="text-[14px] font-medium">
          If you have any questions regarding these Terms or the Service, please contact us at
          info@restaurantreport.ai
        </p>
      </div>
    </div>
  )
}

export default TermsOfService;
