import { useState, useContext } from 'react';
import DateRangePicker from "@wojtekmaj/react-daterange-picker";

import { RestaurantContext } from '../RestaurantContext';

import { ReactComponent as SearchIcon } from '../../assets/search-icon.svg';
import { ReactComponent as CaretIcon } from '../../assets/caret-icon.svg';
import { ReactComponent as StarIcon } from '../../assets/star-icon.svg';

import searchTopics from '../../data/searchTopics.json';
import searchNetworks from '../../data/searchNetworks.json';

const NoResultSearchForm = ({ onSearch }) => {
  const { availableNetworks, minSelectorDate, maxSelectorDate } = useContext(RestaurantContext);

  const [text, setText] = useState('');

  const [selectedNetworks, setSelectedNetworks] = useState([]);
  const [selectedTopics, setSelectedTopics] = useState([]);
  const [selectedRatings, setSelectedRatings] = useState([]);
  const [selectedDateRange, setSelectedDateRange] = useState([minSelectorDate, maxSelectorDate]);

  const [isPlatformOpen, setIsPlatformOpen] = useState(false);
  const [isTopicOpen, setIsTopicOpen] = useState(false);
  const [isRatingsOpen, setIsRatingsOpen] = useState(false);

  const handleSearch = (e) => {
    e.preventDefault();

    const payload = {
      text,
      networks: selectedNetworks,
      topics: selectedTopics,
      ratings: selectedRatings,
      sort: { name: 'Date', value: 'date' },
      sortDirection: 'desc'
    }

    if (selectedDateRange?.[0]) {
      payload.startDate = selectedDateRange[0];
    }

    if (selectedDateRange?.[1]) {
      payload.cutOffDate = selectedDateRange[1];
    }

    onSearch(payload);
  }

  const handleNetworkChecked = (network) => {
    if (selectedNetworks.includes(network)) {
      setSelectedNetworks(selectedNetworks.filter((selectedNetwork) => selectedNetwork !== network));
    } else {
      setSelectedNetworks([...selectedNetworks, network]);
    }
  }

  const handleTopicChecked = (topic) => {
    if (selectedTopics.includes(topic)) {
      setSelectedTopics(selectedTopics.filter((selectedTopic) => selectedTopic !== topic));
    } else {
      setSelectedTopics([...selectedTopics, topic]);
    }
  }

  const handleRatingChecked = (rating) => {
    if (selectedRatings.includes(rating)) {
      setSelectedRatings(selectedRatings.filter((selectedRating) => selectedRating !== rating));
    } else {
      setSelectedRatings([...selectedRatings, rating]);
    }
  }

  let networkSelectionText = 'All';

  if (selectedNetworks.length === 1) {
    networkSelectionText = searchNetworks.find(network => network.value === selectedNetworks[0]).name;
  }

  if (selectedNetworks.length > 1) {
    networkSelectionText = searchNetworks.find(network => network.value === selectedNetworks[0]).name + ' + ' + (selectedNetworks.length - 1);
  }

  if (selectedNetworks.length === availableNetworks.length) {
    networkSelectionText = 'All';
  }

  let topicSelectionText = 'All';

  if (selectedTopics.length === 1) {
    topicSelectionText = searchTopics.find(topic => topic.value === selectedTopics[0]).name;
  }

  if (selectedTopics.length > 1) {
    topicSelectionText = searchTopics.find(topic => topic.value === selectedTopics[0]).name + ' + ' + (selectedTopics.length - 1);
  }

  if (selectedTopics.length === searchTopics.length) {
    topicSelectionText = 'All';
  }

  let ratingSelectionText = 'All';

  if (selectedRatings.length === 1) {
    ratingSelectionText = selectedRatings[0];
  }

  if (selectedRatings.length > 1) {
    ratingSelectionText = selectedRatings.join(', ');
  }

  if (selectedRatings.length === 5) {
    ratingSelectionText = 'All';
  }

  return (
    <form className="flex flex-col md:flex-row items-center gap-[16px] w-full md:w-auto" onSubmit={handleSearch}>
      <label className="flex flex-col gap-[8px] w-full md:min-w-[240px] md:max-w-[240px]">
        <span className="text-white font-medium text-[17px]">Keyword</span>

        <div className="relative w-full">
          <SearchIcon className="absolute top-[50%] left-[16px] transform -translate-y-1/2 stroke-[#556277]" />

          <input
            type="text"
            className="h-[72px] p-[16px] pl-[52px] bg-[#1D2839] rounded-md text-[16px] text-white border-none w-full"
            placeholder="Any keyword"
            value={text}
            onChange={e => setText(e.target.value)}
          />
        </div>
      </label>

      <div className='flex gap-4 w-full md:w-auto'>
        <div className="flex flex-col gap-[8px] w-full md:w-[150px]">
          <span className="text-white font-medium text-[17px]">Platforms</span>

          <div className="relative">
            <div className='w-full select-none' onClick={e => setIsPlatformOpen(!isPlatformOpen)}>
              <div className='flex items-center h-[72px] p-[16px] bg-[#1D2839] rounded-md border-none w-full cursor-pointer'>
                <p className='text-[16px] font-medium text-white'>{networkSelectionText}</p>
              </div>

              <CaretIcon className={`${isPlatformOpen ? 'rotate-180 stroke-r-blue' : ''} absolute top-[50%] right-[20px] stroke-[#728096] -translate-y-1/2 pointer-events-none`} />
            </div>

            <div className={`${isPlatformOpen ? '' : 'hidden'} absolute top-[95%] w-full bg-[#1D2839] z-10`}>
              <div className="flex flex-col gap-[13px] p-[16px] rounded-md text-[16px] text-white border-none w-full rounded-b-md">
                {searchNetworks.filter(network => availableNetworks.includes(network.value)).map((network) => (
                  <label key={`network-${network.value}`} className="flex items-center text-white font-semibold text-[14px]">
                    <input
                      type="checkbox"
                      checked={selectedNetworks.includes(network.value)}
                      onChange={(e) => handleNetworkChecked(network.value)}
                      className='mr-[8px] bg-transparent border border-[#B9BECF] checked:bg-r-blue focus:bg-r-blue checked:focus:bg-r-blue focus:ring-0 rounded-sm hover:bg-r-blue checked:hover:bg-r-blue focus:outline-none hover:ring-0 focus:border-none'
                    />

                    {network.name}
                  </label>
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-[8px] w-full md:w-[135px]">
          <span className="text-white font-medium text-[17px]">Topics</span>

          <div className="relative">
            <div className='w-full select-none' onClick={e => setIsTopicOpen(!isTopicOpen)}>
              <div className='flex items-center h-[72px] p-[16px] bg-[#1D2839] rounded-md border-none w-full cursor-pointer'>
                <p className='text-[16px] font-medium text-white'>{topicSelectionText}</p>
              </div>

              <CaretIcon className={`${isTopicOpen ? 'rotate-180 stroke-r-blue' : ''} absolute top-[50%] right-[20px] stroke-[#728096] -translate-y-1/2 pointer-events-none`} />
            </div>

            <div className={`${isTopicOpen ? '' : 'hidden'} absolute top-[95%] w-full bg-[#1D2839]`}>
              <div className="flex flex-col gap-[13px] p-[16px] rounded-md text-[16px] text-white border-none w-full rounded-b-md">
                {searchTopics.map((topic) => (
                  <label key={`topic-${topic.value}`} className="flex items-center text-white font-semibold text-[14px]">
                    <input
                      type="checkbox"
                      checked={selectedTopics.includes(topic.value)}
                      onChange={e => handleTopicChecked(topic.value)}
                      className='mr-[8px] bg-transparent border border-[#B9BECF] checked:bg-r-blue focus:bg-r-blue checked:focus:bg-r-blue focus:ring-0 rounded-sm hover:bg-r-blue checked:hover:bg-r-blue focus:outline-none hover:ring-0 focus:border-none'
                    />
                    {topic.name}
                  </label>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-[8px] w-full md:w-[130px]">
        <span className="text-white font-medium text-[17px]">Ratings</span>

        <div className="relative">
          <div className='w-full select-none' onClick={e => setIsRatingsOpen(!isRatingsOpen)}>
            <div className='flex items-center h-[72px] p-[16px] bg-[#1D2839] rounded-md border-none w-full cursor-pointer'>
              <p className='text-[16px] font-medium text-white'>{ratingSelectionText}</p>
            </div>

            <CaretIcon className={`${isRatingsOpen ? 'rotate-180 stroke-r-blue' : ''} absolute top-[50%] right-[20px] stroke-[#728096] -translate-y-1/2 pointer-events-none`} />
          </div>

          <div className={`${isRatingsOpen ? '' : 'hidden'} absolute top-[95%] w-full bg-[#1D2839]`}>
            <div className="flex flex-col gap-[13px] p-[16px] rounded-md text-[16px] text-white border-none w-full rounded-b-md">
              {[1, 2, 3, 4, 5].map((rating) => (
                <label key={`topic-${rating}`} className="flex items-center text-white font-semibold text-[14px]">
                  <input
                    type="checkbox"
                    checked={selectedRatings.includes(rating)}
                    onChange={e => handleRatingChecked(rating)}
                    className='mr-[8px] bg-transparent border border-[#B9BECF] checked:bg-r-blue focus:bg-r-blue checked:focus:bg-r-blue focus:ring-0 rounded-sm hover:bg-r-blue checked:hover:bg-r-blue focus:outline-none hover:ring-0 focus:border-none'
                  />

                  {Array.from({ length: rating }, (_, i) => (
                    <StarIcon key={i} className="w-2 h-2 fill-current text-yellow-500 mr-1" />
                  ))}
                </label>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-[8px] w-full md:w-[250px]">
        <span className="text-white font-medium text-[17px]">Date Range</span>

        <DateRangePicker
          value={selectedDateRange}
          onChange={setSelectedDateRange}
          maxDate={new Date()}
          className="no-result-datepicker"
          dayPlaceholder='dd'
          monthPlaceholder='mm'
          yearPlaceholder='yyyy'
          clearIcon={null}
        />
      </div>

      <button
        className="h-[72px] bg-[#18A6C5] self-end w-full md:w-[150px] rounded-md text-white font-semibold text-[15px]"
        type="submit"
      >
        Search
      </button>
    </form>
  )
}

export default NoResultSearchForm;
