import { ReactComponent as StarIcon } from '../../assets/star-icon.svg';

const RatingMap = ({ ratingMap }) => {
  const averageRating = () => {
    const totalRating = Object.keys(ratingMap)?.reduce((acc, rating) => {
      return acc + (rating * ratingMap[rating]);
    }, 0);

    return totalRating > 0 ? Number(Number(totalRating / totalReviews).toFixed(1)) : 0;
  }

  const totalReviews = Object.keys(ratingMap)?.reduce((acc, rating) => {
    return acc + ratingMap[rating];
  }, 0);

  [1, 2, 3, 4, 5].forEach(rating => {
    const ratingExists = ratingMap[rating];

    if (!ratingExists) {
      ratingMap[rating] = 0;
    }
  });

  const ratingMapPercentages = Object.keys(ratingMap)?.reduce((acc, rating) => {
    acc[rating] = (ratingMap[rating] / totalReviews) * 100;

    return acc;
  }, {});

  return (
    <div className="bg-[#202731] p-[16px] md:px-[16px] md:py-[32px] gap-2 rounded-lg flex mb-[16px]">
      <div className="flex flex-col gap-1 w-[40%] items-center justify-center">
        <p className="font-semibold text-[40px] md:text-[54px] leading-none text-r-blue">{averageRating()}</p>
        <p className="font-semibold text-white text-[20px] md:text-[23px]">Total</p>
        <p className="font-semibold text-[#728096] text-[14px] md:text-[16px]">{totalReviews} Reviews</p>
      </div>

      <div className="flex flex-col gap-3 flex-grow">
        {[5, 4, 3, 2, 1].map(rating => (
          <div
            className="flex gap-1 text-white font-bold items-center text-[16px]"
            key={`rating-${rating}`}
          >
            {rating} <StarIcon className="fill-r-blue h-[16px]" />

            <div className="h-[8px] rounded-lg flex-grow bg-[#364151]">
              <div
                style={{ width: `${ratingMapPercentages[rating]}%` }}
                className="bg-[#565B6D] h-[8px] rounded-lg">
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default RatingMap;
