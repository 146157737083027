import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { formatDate } from 'date-fns/format';

const UserProfile = ({ user, onUpdateProfile, onLogoutClicked, onRevokeCancelationClicked, onCancelPlanClicked, onDeleteProfileClicked }) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');

  useEffect(() => {
    if (user) {
      setEmail(user.email);
      setFirstName(user.firstName);
      setLastName(user.lastName);
    }
  }, [user]);

  const handleUpdateProfileClicked = (e) => {
    e.preventDefault();

    onUpdateProfile({
      email,
      firstName,
      lastName
    });
  }

  const PlanStatus = ({ user }) => {
    const planTextMap = {
      active: 'Active',
      canceled: 'Canceled',
      trialing: 'Trial'
    };

    const statusColorMap = {
      active: 'text-green-500',
      canceled: 'text-red-500',
      trialing: 'text-green-500'
    };

    return (
      <p className='flex items-center justify-between'>
        <span className='text-[#9CABC4] text-[16px] font-medium'>Status:</span>
        <span className={`${statusColorMap[user?.subscription.status]} text-[16px] font-medium`}>
          {planTextMap[user?.subscription.status]}

          <span className='text-gray-400'>
            {user?.subscription?.cancel_at_period_end && ' (Canceled)'}
          </span>
        </span>
      </p>
    )
  }

  return (
    <div className='flex flex-col items-center justify-center flex-grow px-[16px] md:px-0 py-[50px]'>
      <h2 className='text-center text-[32px] font-medium text-white mb-[24px]'>Your Account</h2>

      <div className='flex flex-col gap-2 w-full md:w-[500px] items-center'>
        <form className='w-full text-center'>
          <label className='flex flex-col gap-[16px] mb-[24px] w-full'>
            <span className='text-white font-medium text-[17px]'>Your E-mail</span>

            <input
              type='email'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className='rounded-md bg-[#1D2839] h-[72px] text-white p-[24px] focus:border-r-blue border-r-gray-light'
            />
          </label>

          <div className='grid grid-cols-2 gap-4 w-full'>
            <label className='flex flex-col gap-[16px] mb-[24px] w-full'>
              <span className='text-white font-medium text-[17px]'>First Name</span>

              <input
                type='text'
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                className='rounded-md bg-[#1D2839] h-[72px] text-white p-[24px] focus:border-r-blue border-r-gray-light'
              />
            </label>

            <label className='flex flex-col gap-[16px] mb-[24px] w-full'>
              <span className='text-white font-medium text-[17px]'>Last Name</span>

              <input
                type='text'
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                className='rounded-md bg-[#1D2839] h-[72px] text-white p-[24px] focus:border-r-blue border-r-gray-light'
              />
            </label>
          </div>

          <button
            onClick={handleUpdateProfileClicked}
            className='bg-r-blue text-white font-semibold text-[15px] p-[16px] text-center rounded-md mb-2'
          >
            Update profile
          </button>
        </form>

        <div className='flex flex-col mb-[50px] w-full'>
          <p className='text-white font-medium text-[17px] mb-[16px]'>Subscription plan</p>

          <div className='bg-[#1D2839] p-[24px] rounded-md flex flex-col gap-[24px]'>
            <p className='flex items-center justify-between'>
              <span className='text-[#9CABC4] text-[16px] font-medium'>Type:</span>
              <span className='text-white text-[16px] font-medium'>{user?.subscription?.planName}</span>
            </p>

            <p className='flex items-center justify-between'>
              <span className='text-[#9CABC4] text-[16px] font-medium'>Plan Price:</span>
              <span className='text-white text-[16px] font-medium'>${user?.subscription.items.data[0].price.unit_amount / 100}</span>
            </p>

            <PlanStatus user={user} />

            <p className='flex items-center justify-between'>
              <span className='text-[#9CABC4] text-[16px] font-medium'>
                {user?.subscription?.cancel_at_period_end ? 'Subscription expires on:' : 'Plan Renews on:'}
              </span>

              <span className='text-white text-[16px] font-medium'>
                {user && formatDate(new Date(user?.subscription?.current_period_end * 1000), 'MMM do, yyyy')}
              </span>
            </p>

            <div className='grid grid-cols-2 gap-2'>
              <Link to={user?.subscription?.cancel_at_period_end ? '/profile' : '/plans'}>
                <div className={`${user?.subscription?.cancel_at_period_end ? 'bg-[#394558] pointer-events-none cursor-not-allowed' : 'bg-r-blue cursor-pointer'} text-white font-semibold text-[15px] p-[16px] text-center rounded-md w-full h-full`}>
                  Change Plan
                </div>
              </Link>

              {user?.subscription.cancel_at_period_end
                ? (
                  <button
                    onClick={onRevokeCancelationClicked}
                    className='bg-r-blue text-white font-semibold text-[15px] p-[16px] text-center rounded-md cursor-pointer'
                  >
                    Revoke cancelation
                  </button>
                )
                : (
                  <button
                    onClick={onCancelPlanClicked}
                    className='bg-[#394558] text-white font-semibold text-[15px] p-[16px] text-center rounded-md cursor-pointer'
                  >
                    Cancel Plan
                  </button>
                )
              }
            </div>
          </div>
        </div>

        <div className='grid grid-cols-2 w-full gap-4'>
          <button
            onClick={onLogoutClicked}
            type="button"
            className='bg-transparent text-red-500 border border-red-500 font-bold p-2 rounded-md py-[16px] text-[15px] w-full'>
            Log Out
          </button>

          <button
            onClick={onDeleteProfileClicked}
            type="button"
            className='bg-red-500 border text-white border-none font-bold p-2 rounded-md py-[16px] text-[15px] w-full'>
            Delete Profile
          </button>
        </div>
      </div>
    </div>
  );
}

export default UserProfile;
