const SEARCH_NETWORKS = [
  {
    "name": "Google",
    "value": "google"
  },
  {
    "name": "Yelp",
    "value": "yelp"
  },
  {
    "name": "OpenTable",
    "value": "opentable"
  },
  {
    "name": "GrubHub",
    "value": "grubhub"
  },
  {
    "name": "DoorDash",
    "value": "doordash"
  },
  {
    "name": "UberEats",
    "value": "ubereats"
  },
  {
    "name": "TripAdvisor",
    "value": "tripadvisor"
  },
  {
    "name": "Instagram",
    "value": "instagram"
  }
];

export const API_URL = process.env.REACT_APP_API_URL;
export { SEARCH_NETWORKS };

