import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';

import './index.css';
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import 'react-calendar/dist/Calendar.css';

import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate
} from "react-router-dom";

import Root from './routes/root';
import { AuthProvider } from './components/AuthContext';

import Dashboard from './components/Dashboard';
import RegistrationForm from './components/RegisterForm';
import LoginForm from './components/LoginForm';
import Profile from './components/Profile';
import ForgotPasswordForm from './components/ForgotPassword';
import CreateNewPasswordForm from './components/CreateNewPassword';
import Search from './components/Search';
import Insights from './components/Insights';
import FindRestaurant from './components/FindRestaurant';
// import FindInstagram from './components/FindInstagram';
import UserPlans from './components/UserPlans';
import DashboardLayout from './layouts/DashboardLayout';
import Opportunities from './components/Opportunities';
import RestaurantSpy from './components/RestaurantSpy';
import TrialRegistrationForm from './components/TrialRegistrationForm';

function App() {
  return (
    <Router>
      <AuthProvider>
        <Routes>
          <Route path="/" element={<Root />}>
            <Route element={<DashboardLayout />}>
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/search" element={<Search />} />
              <Route path="/insights" element={<Insights />} />
              <Route path="/opportunities" element={<Opportunities />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/find-restaurant" element={<FindRestaurant />} />
              {/* <Route path="/find-instagram" element={<FindInstagram />} /> */}
              <Route path="/plans" element={<UserPlans />} />
              <Route path="/spy" element={<RestaurantSpy />} />
            </Route>

            <Route path="/login" element={<LoginForm />} />
            <Route path="/register" element={<RegistrationForm />} />
            <Route path="/trials" element={<TrialRegistrationForm />} />
            <Route path="/forgot-password" element={<ForgotPasswordForm />} />
            <Route path="/create-new-password/:id" element={<CreateNewPasswordForm />} />

            <Route path="*" element={<Navigate to="/login" replace />} />
          </Route>
        </Routes>
      </AuthProvider>
    </Router>
  )
}
ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <App />

    <div id="portal"></div>
  </React.StrictMode>
);

reportWebVitals();
