const DeleteProfilePrompt = ({ onConfirmClicked, onCancelClicked }) => {
  return (
    <div className="bg-[#1D2839] rounded-lg p-[32px] text-center text-white w-[508px]">
      <h1 className="text-white text-[24px] leading-[32px] mb-2 font-medium">Delete Profile</h1>

      <p className="text-[#9CABC4] text-[18px] leading-[24px] mb-6">Are you sure you want to delete your profile? This action is irreversible.</p>

      <div className="grid grid-cols-2 gap-4">
        <button
          onClick={onConfirmClicked}
          className="bg-r-blue text-white font-semibold text-[16px] h-[48px] flex items-center justify-center rounded-md"
        >
          Yes, Delete Profile
        </button>

        <button
          onClick={onCancelClicked}
          className="bg-[#394558] text-white font-semibold text-[16px] h-[48px] flex items-center justify-center rounded-md"
        >
          No
        </button>
      </div>
    </div>
  )
}

export default DeleteProfilePrompt;
