import { useCallback, useEffect, useState } from "react";

import apiService from "../services/apiService";

import useInterval from "./useInterval";

function useSnapshots({ user, userRestaurant, token, setUser }) {
  const [snapshots, setSnapshots] = useState(null);

  const [isLoadingSnapshots, setIsLoadingSnapshots] = useState(false);
  const [hasFetchedSnapshots, setHasFetchedSnapshots] = useState(false);

  const [restaurantSelectOptions, setRestaurantSelectOptions] = useState([]);

  const isCreatingSnapshot = user?.creatingSnapshots?.length > 0;

  const fetchSnapshots = useCallback(async () => {
    setIsLoadingSnapshots(true);

    try {
      const response = await apiService.fetchUserSnapshots({ userId: user._id });

      const snapshots = response.data;

      for (const snapshot of snapshots) {
        snapshot.restaurant.isSnapshot = true;
      }

      setSnapshots(snapshots);
      setHasFetchedSnapshots(true);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingSnapshots(false);
    }
  }, [user?._id]);

  useEffect(() => {
    let options = [];

    if (snapshots) {
      for (const snapshot of snapshots) {
        const snapshotDate = new Date(snapshot.dateCreated).toLocaleDateString();

        options.push({
          value: snapshot.restaurant._id,
          label: snapshot.restaurant.google.name,
          restaurant: snapshot.restaurant,
          dateCreated: snapshot.dateCreated,
          snapshotDate
        });
      }
    }

    if (user?.creatingSnapshots) {
      for (const snapshot of user.creatingSnapshots) {
        options.unshift({
          value: `${snapshot.snapshotName} - ${snapshot._id}`,
          label: snapshot.snapshotName,
          isCreatingSnapshot: true,
          isDisabled: true
        });
      }
    }

    if (userRestaurant) {
      options.unshift({ value: userRestaurant?._id, label: userRestaurant?.google.name, restaurant: userRestaurant });
    }

    setRestaurantSelectOptions(options);
  }, [snapshots, userRestaurant, user, isCreatingSnapshot]);

  const updateUser = async () => {
    const response = await apiService.fetchUserFromToken({ token });

    setUser(response.data);
  }

  useEffect(() => {
    if (user && !isLoadingSnapshots && !hasFetchedSnapshots) {
      fetchSnapshots();
    }
  }, [user, isLoadingSnapshots, hasFetchedSnapshots, fetchSnapshots]);

  useInterval(() => {
    const fetchSnapshots = async () => {
      await fetchSnapshots();
      await updateUser();
    };

    fetchSnapshots();
  }, isCreatingSnapshot ? 30 * 1000 : null);

  return {
    snapshots,
    setSnapshots,
    isLoadingSnapshots,
    hasFetchedSnapshots,
    setHasFetchedSnapshots,
    restaurantSelectOptions
  };
}

export default useSnapshots;
