import Select, { components, } from 'react-select';

import Loader from '../Loader';

import { ReactComponent as HomeIcon } from '../../assets/home-icon.svg';
import { ReactComponent as SpyIcon } from '../../assets/spy-icon.svg';

const CustomSingleValue = (props) => {
  return (
    <components.SingleValue {...props}>
      <div className='flex items-center justify-between w-full'>
        <div className='flex items-center gap-2 flex-grow'>
          {props.data.snapshotDate
            ? <SpyIcon className="stroke-white h-4" />
            : <HomeIcon className="stroke-white h-4" />
          }

          {props.data.label}
        </div>
      </div>
    </components.SingleValue>
  )
}

const CustomOption = (props) => {
  return (
    <components.Option {...props}>
      <div className="flex items-center gap-4">
        {props.data.snapshotDate || props.data.isCreatingSnapshot
          ? <SpyIcon className="stroke-white h-4" />
          : <HomeIcon className="stroke-white h-4" />
        }

        <div className='flex items-center justify-between w-full'>
          <p className="font-semibold text-white">{props.data.label}</p>

          {props.data.isCreatingSnapshot && <Loader isExtraSmall={true} />}
        </div>
      </div>
    </components.Option>
  );
}

const RestaurantSelect = (props) => {
  return <Select
    {...props}
    components={{
      SingleValue: CustomSingleValue,
      Option: CustomOption,
      IndicatorSeparator: () => null
    }}
    styles={{
      container: (styles) => ({
        ...styles,
        width: '100%'
      }),
      control: (styles) => ({
        ...styles,
        backgroundColor: '#17202e',
        borderColor: '#2e425f',
        width: '100%',
        marginBottom: '0',
        minHeight: '56px'
      }),
      input: (styles) => ({
        ...styles,
        outline: 'none',
        border: 'none',
        color: 'white',
        boxShadow: 'none'
      }),
      singleValue: (styles) => ({
        ...styles,
        color: '#fff',
        fontWeight: '600',
        fontSize: '16px',
        padding: '10px 5px'
      }),
      dropdownIndicator: (styles) => ({
        ...styles,
        fill: '#18A6C5'
      }),
      menuList: (styles) => ({
        ...styles,
        paddingTop: 0,
        paddingBottom: 0
      }),
      menuPortal: (styles) => ({
        ...styles,
        border: '1px solid #18A6C5',
      }),
      option: (styles, { isFocused, isSelected }) => ({
        ...styles,
        backgroundColor: isSelected ? '#18A6C5' : isFocused ? '#2C3E50' : '#17202e',
        color: 'white',
        fontWeight: '600'
      })
    }} />
}

export default RestaurantSelect;
